.home{
    height: 100vh;
    .background-wrapper{
        width: 100vw;
        height: 100vh;
        opacity: 0.5;
        position: absolute;
        background: black;
        z-index: -11;
    }
    .background-image{
        z-index: -1111;
        position: absolute;
        width: 100vw;
    }
    .hero-container{
        z-index: 1111;
        padding-top: 25vh;
        display: flex;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        .hero-header{
            color:white;
            h1{
                font-family: 'Jomolhari', serif;
                font-size:60px;
                margin-bottom: 10px;
            }
            h2{
                margin-top: 0;
                font-size: 40px;
                font-weight: 300;
            }
            .links{
                display: flex;
                flex-direction: row;
                text-decoration: none;
                align-items: center;
                justify-content: space-evenly;
                .instagram{
                    background-color: none;
                    border-radius: 5px;
                    width: 30px;
                    height: 30px;
                    a{
                        align-content: center;
                        font-size: 35px;
                        color: white;
                    }
                }
                .contact-button{
                    .basic-button{
                        font-size: 18px;
                        border: 1px solid white;
                        padding: 8px;
                        border-radius: 20px;
                        text-decoration: none;
                        color: white;
                    }
                }
            }
        }
        .logo{
                display: inline;
                img{
                    width: 100px;
                }
            }
    }
}


//Media Query //
@media(max-width:840px){
    .home{
        .hero-container{
            padding-top: 15vh;
            display: block;
        
        .hero-header{
            h1{
                font-size:40px;
            }
            h2{
                font-size: 30px;
            }
            .links{
                flex-direction: column;
                .instagram, .contact-button{
                    padding: 30px;
                }
            }   
        }
    }
    .background-image{
        height: 100vh;
    }
    }

    
}

@media(max-width:480px){

}