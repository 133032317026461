.about-section{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background-color: #a52121;
    height: 60vh;
    color: #fafafa;
    hr{
        width: 5vw;
        border: 2px solid white;
    }
    h2{
        font-size: 45px;
        margin: 15px;
    }
    hr{
        margin-bottom: 5vh;
    }
    .about-text-row{
        width: 30vw;
        margin: 0 auto;
        p{
            font-size: 20px;
        }
    }
}

/*----Media Query ---*/

@media(max-width:840px){
    .about-section{
        .about-text-row{
            width: auto;
            margin: 0 8vw;

        
    }
    }
    
}

@media(max-width:480px){
    .about-section{
        .about-text-row{
            width: auto;
            margin: 0 8vw;

        
    }
    }
    
}