.contact-section{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background-color: gray;
    height: 80vh;
    hr{
        width: 5vw;
        border: 2px solid black;
        margin-bottom: 5vh;
    }
    h2{
        font-size: 45px;
        margin: 15px;
    }
    .contact-row{
        text-align: center;
        background: #e0e0e0;
        padding: 14vh 8vw;
        box-sizing: border-box;
        box-shadow: 0 0 20px #000000b3;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        font-size: 19px;

        .contact-details{
            margin-right: 2vw;
            .contact-input-row{
                display: flex;
                flex-direction: column;
                text-align: left;
                padding: 5px 0;
                input{
                    padding: .5vh 6vw;
                }
                label{
                    padding: 10px 0;
                }
            }
        }
        .button{
            font-size: 18px;
            border: 1px solid black;
            margin-top: 10px;
            padding: 4px 8px;
            border-radius: 5px;
            text-decoration: none;
            color: black;
        }
    }

}

//Media Query //
@media(max-width:840px){
    .contact-section{
       .contact-row{
        flex-direction: column;
        padding: 5vh 8vw;
        .contact-input-row{
            input{
                padding: .5vh 10vw;
            }
        }
    } 
    }
    
    
}

@media(max-width:480px){

    
}