.services-section{
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        background-color: #fafafa;
        height: 80vh;
        hr{
            width: 5vw;
            border: 2px solid black;
            margin-bottom: 5vh;
        }
        h2{
            font-size: 45px;
            margin: 15px;
        }
        .services-text-row{
            display: flex;
            flex-direction: row;
            margin: 0 20vw;
            h3{
                font-size: 30px;
            }
            p{
                font-size: 19px;
                line-height: 28px;
                padding: 25px;
            }
        }    
}


//Media Query //
@media(max-width:840px){
    .services-section{

        height: auto;
        .services-text-row{
            display: block;
            margin: 0 1vw;
            h3{
                margin: 0;
            }
            p{
                padding: 10px 20px;
            }
        }
    }
    
}

@media(max-width:480px){
    .services-section{
        .services-text-row{
            display: block;
        }
    }
    
}