.mainnav-container{
    display: flex;
    flex-direction: row;
    background-color: #a52121;
    align-items: center;
    position: fixed;
    .logo{
        margin-left: 2vw;
        img{
            width: 50px;
        }
    }
    .mainnav-navigation{
        display: flex;
        justify-content: center;
        width: 100vw;

        .navbar{
        list-style: none;
        display: flex;
        flex-direction: row;

            .nav-links{
                padding: 0px 25px;
                font-size: 21px;
                text-decoration: none;
                color: white;
            }
        }
        
    }
}

//Media Query //
@media(max-width:840px){
    .mainnav-container{
        .logo{
            img{
                display: none;
            }
        }
        .mainnav-navigation{
            .navbar{
                padding-left: 0;
                .nav-links{
                    padding: 0px 10px;
                    font-size: 16px;
                    text-decoration: none;
                    color: white;
                }
            }
        }
    }
    
}

@media(max-width:480px){
    .mainnav-container{
        .mainnav-navigation{
        
    }
    }
    
}