@import './MainNav';
@import './MobileNav';
@import './Home';
@import './About';
@import './Services';
@import './Contact';
@import './Footer';
@import './OurGoals';

@import url('https://fonts.googleapis.com/css2?family=Fraunces:wght@300;400&family=Jomolhari&display=swap');

body{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Fraunces', serif;
}

/*---Scrool Styling ---*/

body::-webkit-scrollbar{
    width: .6vw
}
body::-webkit-scrollbar-track{
    background-color:#191919 ;
}
body::-webkit-scrollbar-thumb{
    background-color:#a52121;
}